import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as DownIcon } from 'assets/icons/down.svg';
import { ReactComponent as UpIcon } from 'assets/icons/up.svg';
import { ReactComponent as Left } from 'assets/icons/left.svg';
import { ReactComponent as Right } from 'assets/icons/right.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as NoCircleCheck } from 'assets/icons/no-circle-check.svg';

export const icons = {
  search: SearchIcon,
  down: DownIcon,
  up: UpIcon,
  left: Left,
  right: Right,
  location: Location,
  mail: Mail,
  phone: Phone,
  noCircleCheck: NoCircleCheck,
};
