import styled from '@emotion/styled';
import { Router } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import history from 'util/history';
import Header from 'components/layout/Header';
import RootRoutes from 'routes/RootRoutes';
const AppContainer = styled.main``;

const BodyContainer = styled.div``;

const Content = styled.div`
  position: relative;
  min-height: 100vh;
`;

function App() {
  const [initialized, setInitialized] = useState(false);
  const [historyState, setHistoryState] = useState({
    action: history.action,
    location: history.location,
  });
  const { pathname } = historyState.location;

  useLayoutEffect(() => history.listen(setHistoryState), []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('G-ZK7DJHZ65F');
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [initialized, pathname]);

  return (
    <Router
      navigator={history}
      location={historyState.location}
      navigationType={historyState.action}
    >
      <AppContainer>
        <Header />
        <BodyContainer>
          <Content>
            <RootRoutes />
          </Content>
        </BodyContainer>
      </AppContainer>
    </Router>
  );
}

export default App;
