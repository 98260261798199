import { createContext, useContext, useState, ReactNode } from 'react';

interface ScrollContextType {
  isScrolling: boolean;
  setIsScrolling: (value: boolean) => void;
}

const ScrollContext = createContext<ScrollContextType>({} as ScrollContextType);

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  return (
    <ScrollContext.Provider value={{ isScrolling, setIsScrolling }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error(
      'useScroll은 반드시 ScrollProvider 내부에서 사용해야 합니다.',
    );
  }
  return context;
};
