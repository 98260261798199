import { useLayoutEffect } from 'react';
import PageLayout from 'components/layout/PageLayout';
import SectionA from './SectionA';
import SectionB from './SectionB';
import { useScroll } from 'store/ScrollContext';

const Team = () => {
  const { setIsScrolling } = useScroll();
  useLayoutEffect(() => {
    setIsScrolling(true);
  }, [setIsScrolling]);

  return (
    <PageLayout scrollSnap={false}>
      <SectionA />
      <SectionB />
    </PageLayout>
  );
};

export default Team;
