import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useScroll from 'hooks/useScroll';
import { breakPoint } from 'shared/styles';

const SectionA = () => {
  const target = useScroll();
  const isTablet = useMediaQuery({ query: breakPoint.TABLET });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isLD = useMediaQuery({ query: breakPoint.LARGE_DEVICE });

  return (
    <Container ref={target} isLD={isLD}>
      <Flex
        align="center"
        css={css`
          height: 100%;
        `}
      >
        <TitleContainer>
          <Typography.Title
            color="WHITE"
            css={css`
              ${isTablet &&
              css`
                font-size: 36px;
                line-height: 46px;
              `}
              ${isMobile &&
              css`
                font-size: 24px;
                line-height: 34px;
              `}
            `}
          >
            새 역사를 쓰는 CDRI의 혁신
          </Typography.Title>
          <Typography.Title
            type="b1"
            color="WHITE"
            css={css`
              margin-top: 36px;
              word-break: keep-all;

              ${isMobile &&
              css`
                margin-top: 32px;
                font-size: 16px;
                line-height: 24px;
              `}
            `}
          >
            CDRI는 IT기술과 데이터로 뷰티테크 시장 혁신을 주도하고 있으며,
            {!isMobile && <br />}
            뷰티 산업에 관한 데이터 구축을 통해 뷰티 경영활동을 돕고
            {!isMobile && <br />} 나아가 뷰티 산업 생태계 육성을 기여하고
            있습니다.
          </Typography.Title>
        </TitleContainer>
      </Flex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section<{ isLD: boolean }>`
  position: relative;
  background-image: url('https://cdri.s3.ap-northeast-2.amazonaws.com/assets/business-a.png');
  background-size: cover;
  background-position: center;

  ${({ isLD }) =>
    isLD &&
    css`
      height: 100vh;

      padding: 0 24px !important;
    `}
`;

const TitleContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  position: relative;
`;
