import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';

import './index.css';
import App from './App';
import { palette } from 'shared/styles';
import { ScrollProvider } from 'store/ScrollContext';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <ScrollProvider>
      <ThemeProvider theme={{ palette: palette }}>
        <App />
      </ThemeProvider>
    </ScrollProvider>,
  );
}
