import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoint, palette } from 'shared/styles';

const SectionA = () => {
  const isSmallDevice = useMediaQuery({
    query: breakPoint.SMALL_DEVICE,
  });
  const contents = [
    {
      type: 'VISION',
      title: '우리는 더 나은 뷰티산업을 만듭니다',
      description: 'We Create a Better Beauty Industry.',
      delay: 0,
    },
    {
      type: 'MISSION',
      title: `원료부터 생산 이후의 수출과 유통과 마케팅까지${
        isSmallDevice ? ' ' : '\n'
      }화장품 산업 전체 프로세스의 효율화를 위해 솔루션을 제공합니다.`,
      description: `We provide solutions to streamline the entire process of the Beauty Industry, from raw${
        isSmallDevice ? ' ' : '\n'
      }materials to export and distribution after production.`,
      delay: 1,
    },
  ];

  return (
    <Container>
      <ContentWrapper align="center">
        <ContentFlex
          direction="column"
          justify="space-between"
          align="flex-start"
        >
          {contents.map((content) => (
            <Content key={content.type} {...content} />
          ))}
        </ContentFlex>
      </ContentWrapper>
    </Container>
  );
};

export default SectionA;

const Container = styled.section`
  margin: 0 auto;
  background-color: ${palette.LIGHT_GREY};
  height: 100vh;
`;

const ContentWrapper = styled(Flex)`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (${breakPoint.LARGE_DEVICE}) {
    width: 100%;
  }
`;

const ContentFlex = styled(Flex)`
  height: 556px;

  @media screen and (${breakPoint.LARGE_DEVICE}) {
    padding: 0px 24px;
  }
  @media screen and (${breakPoint.MOBILE}) {
    height: 420px;
  }
  @media screen and (${breakPoint.TABLET_TO_XL_DEVICE}) {
    height: 370px;
  }
`;

const Content = ({
  type,
  title,
  description,
  delay,
}: {
  type: string;
  title: string;
  description: string;
  delay: number;
}) => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const target = useIntersectionObserver({ onIntersect: setIsInterSecting });
  return (
    <ContentContainer
      ref={target}
      isIntersecting={isIntersecting}
      delay={delay}
    >
      <Typography.Title
        type="s1"
        color="PRIMARY"
        css={css`
          @media screen and (${breakPoint.MOBILE}) {
            font-size: 16px;
            line-height: 24px;
            word-break: keep-all;
            width: 100%;
          }

          @media screen and (${breakPoint.TABLET_TO_LARGE_DEVICE}) {
            font-size: 18px;
            line-height: 28px;
            word-break: keep-all;
            width: 100%;
          }
        `}
      >
        {type}
      </Typography.Title>
      <Typography.Title
        type="h2"
        css={css`
          white-space: pre-line;
          margin-top: 6px;

          @media screen and (${breakPoint.MOBILE}) {
            margin-top: 4px;
            font-size: 18px;
            line-height: 28px;
            word-break: keep-all;
          }

          @media screen and (${breakPoint.TABLET_TO_LARGE_DEVICE}) {
            margin-top: 8px;
            font-size: 24px;
            line-height: 34px;
            word-break: keep-all;
          }
        `}
      >
        {title}
      </Typography.Title>
      <Typography.Title
        type="h3"
        color="GREY2"
        css={css`
          white-space: pre-line;
          margin-top: 24px;

          @media screen and (${breakPoint.MOBILE}) {
            width: 100%;
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
            word-break: keep-all;
          }

          @media screen and (${breakPoint.TABLET_TO_LARGE_DEVICE}) {
            width: 100%;
            margin-top: 16px;
            font-size: 18px;
            line-height: 28px;
            word-break: keep-all;
          }
        `}
      >
        {description}
      </Typography.Title>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{ isIntersecting: boolean; delay: number }>`
  scroll-snap-align: unset;
  span {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.7s ease-in-out;
    transition-delay: ${({ delay }) => delay}s;

    ${({ isIntersecting }) =>
      isIntersecting &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}
  }

  span[type='s0'] {
    transition-delay: ${({ delay }) => delay + 0.5}s;
  }
`;
