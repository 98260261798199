import { useLayoutEffect } from 'react';

import PageLayout from 'components/layout/PageLayout';
import SectionA from './SectionA';
import SectionB from './SectionB';
import SectionC from './SectionC';
import SectionD from './SectionD';
import { useScroll } from 'store/ScrollContext';

const AboutUs = () => {
  const { setIsScrolling } = useScroll();

  useLayoutEffect(() => {
    setIsScrolling(true);
  }, [setIsScrolling]);
  return (
    <PageLayout scrollSnap={false}>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
    </PageLayout>
  );
};
export default AboutUs;
