import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, useEffect, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { ReactComponent as ArrowLeft } from 'assets/icons/circle-arrow-left.svg';
import PageLayout from 'components/layout/PageLayout';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import { PositionKey } from 'type';
import { useScroll } from 'store/ScrollContext';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    kakao: any;
  }
}

const positionMap: {
  [key in PositionKey]: {
    title: string;
    team: string;
    type: '정규직' | '비 정규직';
    mainTasks: string[];
    qualifications: string[];
    skills?: string[];
    developmentCultures?: { content: string; url?: string }[];
    preferentials?: string[];
    isDeveloper?: boolean;
    year?: string;
    url?: string;
  };
} = {
  'it-platform-sales': {
    title: '화장품 인허가 IT플랫폼 영업',
    team: '자동화솔루션팀',
    type: '정규직',
    mainTasks: [
      '• 화장품 국내/해외 인증 서비스 영업',
      '• 화장품 국내/해외 인증 자동화 프로그램 사용 및 교육',
      '• 인증 대행 및 수행 커뮤니케이션',
      '• 데이터 기반의 화장품 컨설팅',
    ],
    qualifications: [
      '• 학력 : 대졸 이상(2,3년)',
      '• 경력 : 3년 ~ 10년(팀원급)',
    ],
    preferentials: [
      '• 화장품 브랜드사 제품교육 강사 경력자',
      '• 화장품 브랜드사 CS 담당 경력자',
      '• 화장품 브랜드사 및 제조사, 부자재사 영업 경력자',
      '• IT플랫폼 고객유치 영업 경력',
    ],
    year: '3년 ~ 10년(팀원급)',
  },
  'community-manager': {
    title: '커뮤니티 운영 및 기획 매니저',
    team: '경영실',
    type: '정규직',
    mainTasks: [
      '• Cosbridge를 화장품 산업을 연결하여 화장품 산업 대표 플랫폼으로 성장시킵니다.',
      '• 국내 모든 화장품 행사 취합 및 큐레이션',
      '• 화장품 오프라인 세션(행사) 기획 및 운영',
      '• 국내외 화장품 시장 이슈 포착 및 이슈 발생 시, 실시간으로 게시판에 게재.(매뉴얼에 따라 업무)',
      '• 커뮤니티 (브랜드 SNS 채널 포함) 운영 관리',
      '• 커뮤니티 관리를 위한 컨텐츠/이벤트 기획 및 진행',
      '• 유저 동향 파악 및 분석과 리포팅',
      '• 클라이언트, 파트너사와 커뮤니케이션',
      '• 컨텐츠 주간/월간 결과 리포트 제작 및 분석과 리포팅',
      '• 홍보/바이럴 컨텐츠 기획 및 관리',
      '• Cosbridge 커뮤니티를 통한',
      '&nbsp;&nbsp;&nbsp;&nbsp; (향후) 신규 광고주 캠페인 제안 및 기존 광고주 관리',
      '&nbsp;&nbsp;&nbsp;&nbsp; (향후) 신규 광고주 Data base 리스트업 ',
      '&nbsp;&nbsp;&nbsp;&nbsp; (향후) 신규 광고주 맞춤 제안서 및 견적서 작성 ',
      '&nbsp;&nbsp;&nbsp;&nbsp; (향후) 신규 마케팅 상품 아이디어 제안 및 공유 ',
      '&nbsp;&nbsp;&nbsp;&nbsp; (향후) 광고주 커뮤니케이션 및 결과 리포트 작성',
      '• 팀원 관리 (향후 채용 예정)',
    ],
    qualifications: [
      '• 명확하고 핵심을 파악할 수 있는 비즈니스 커뮤니케이션이 가능하신 분',
      '• 상대를 배려하며 프로답게 커뮤니케이션 하는 역량을 함양 하기 위해 노력하고 계신 분',
      '• 검색엔진 및 SNS 서칭 능력이 뛰어난 분',
      '• 커뮤니티 관리 경험이 있으신 분',
      '• 맞춤법, 문법 등 기본적인 글쓰기에 대한 소양을 갖추신 분',
    ],
    preferentials: [
      '• Microsoft Office, Google 을 포함한 제안서 기획 및 작성을 위한 일정 수준 이상의 문서 작성 능력 함양',
      '• 예산과 인력이 기대만큼 충분하지 않아도, 최선의 답을 찾아가는 포지션에서 일해보신 분(스타트업, 본인 사업 등)',
      '• 다양한 커뮤니티 및 SNS를 하시는 분 (인스타그램, 페이스북, 링크드인 등)',
      '• ‘하면 된다’라는 긍정마인드 소유자',
      '• 유연한 협상능력 및 업무 추진력 보유자',
      '• 뛰어난 대인관계 및 비즈니스 센스 소유자',
      '• 영어 커뮤니케이션 능력을 함양하신 분',
      '• 러쉬, 룰루레몬, 나이키 등 에너지 넘치는 오프라인 매장에서 근무하는 것이 잘 맞을 것 같은 분',
      '• 독립적으로도 팀의 일원으로서도 함께 할 수 있는 능력을 함양하신 분',
      '• 서비스 운영, 섭외, 기획 등 직무 연관성 높은 업무에서 5년 안팎의 경력이 있는 분',
      '• 뷰티 산업 종사 경험이 있으신 분',
    ],
    year: '무관',
    url: 'https://www.jobkorea.co.kr/Recruit/GI_Read/46280166?Oem_Code=C1',
  },
  'license-manager': {
    title: '전략기획운영팀 매니저',
    team: '경영실',
    type: '정규직',
    mainTasks: [
      '• CDRI의 주요 고객사인 온라인 커머스사, 유통사, 제조사 등과 전략적인 계약의 구조를 설계, 진행, 운영될 수 있도록 확인하고 관리합니다.',
      '• 화장품 관련 지원 사업을 많이 하는 정부 기관 및 민간 기관 등과 전략적인 계약의 구조를 설계, 계약, 운영될 수 있도록 확인하고 관리합니다.',
      '• CDRI 유관 업체들과의 시너지를 위해 기존/신규 사업을 기획 및 개발하고 운영합니다.',
      '&nbsp;&nbsp;&nbsp;&nbsp; - 제휴처의 특성 및 주요 고객층 분석에 따라 전략적인 계약 구조를 기획하고 제안하여 실행합니다.',
      '&nbsp;&nbsp;&nbsp;&nbsp; - 신규 제휴 요청 건에 대한 검토를 진행하고 계약을 구체화합니다. ',
      '&nbsp;&nbsp;&nbsp;&nbsp; - 대형 제휴처/고객사 및 지정된 고객사를 대상으로 커뮤니케이션하고 관리합니다. ',
      '&nbsp;&nbsp;&nbsp;&nbsp; - 사업 계획에 따라 타겟층을 특정하고 유효한 제휴처에 RFP를 발송합니다.',
      '&nbsp;&nbsp;&nbsp;&nbsp; - 계약 내용의 데이터화를 통해 업무 유관자들과 공유합니다.',
      '• CDRI 고객 경험 향상을 위한 내부 운영을 개선합니다.',
      '• 해외 법인(미국, 독일, 영국, 인도, 중국 등)을 중심으로 한 글로벌 프로젝트에도 한국 본사에서 필요로 되는 전략 기획 및 운영을 지원합니다.',
      '• 팀원 관리 (향후 채용 예정)',
      '• 이외 대표이사 수명 업무, IR 업무 등을 지원합니다.',
    ],
    qualifications: [
      '• 3년 이상의 전략기획 (입찰, 사업개발, 전략기획) 및 관련 직무에서의 검증된 경험을 가지신 분',
      '• 사업기획/영업기획/제휴 업무 등의 업무를 통해 대형 계약 관리 경험이 있으신 분',
      '• 빠르게 변화하는 비즈니스 환경에서 도전적인 업무를 통해 성취감을 느끼실 수 있으신 분',
      '• 명확하고 핵심을 파악할 수 있는 비즈니스 커뮤니케이션이 가능하신 분',
      '• B2B 제휴 영업, 계약 진행 업무 경험이 있으신 분',
    ],
    preferentials: [
      '• Microsoft Office Suite, Google 을 포함한 사업 제안서 기획 및 작성을 위한 일정 수준 이상의 문서 작성 능력 함양',
      '• Unit Economics, Project Finance Model, ROI 등과 같은 비즈니스 지표 해석에 필요로 되는 이해를 가지신 분',
      '• 여러가지 우선순위를 관리하고 기한을 준수할 수 있는 능력을 함양하신 분',
      '• 독립적으로도 팀의 일원으로서도 함께 할 수 있는 능력을 함양하신 분',
      '• 영어 커뮤니케이션 능력을 함양하신 분',
      '• 뷰티 산업 종사 경험이 있으신 분',
    ],
    year: '5년 이상',
    url: 'https://www.jobkorea.co.kr/Recruit/GI_Read/46280132?Oem_Code=C1',
  },
  'ui-ux-designer': {
    title: 'UI/UX 디자이너',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• certicos 신규 웹 서비스 UI/UX 디자인',
      '• certicos 화장품 인증 B2B 서비스 UI/UX 디자인 고도화',
    ],
    qualifications: [
      '• 상용화된 웹 / 앱 서비스의 UI/UX 디자인 경험이 있으신 분',
      '• Sketch, Zeplin 등의 디자인관련 툴 사용가능하신 분',
      '• 협업을 중요시하며 유연한 커뮤니케이션 능력을 지니신 분',
      '• 사용자 관점에서 능동적으로 솔루션을 제시할 수 있는 분',
    ],
    preferentials: [
      '• 적극적이고 주도적으로 서비스를 기획한 경험이 있으신 분',
      '• 구글/애플 디자인 가이드 라인에 대한 깊은 이해도가 있으신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 UI/UX설계부터 출시, 운영 경험이 있으신 분',
    ],
  },
  'e-commerce-planner': {
    title: '서비스 기획자',
    team: '서비스개발본부',
    type: '정규직',
    mainTasks: [
      '• 기획팀장, UI/UX 디자이너로 구성된 기획/디자인팀에서 개발자와 함께 서비스 개발 빛 고도화',
      '• 신규 웹 서비스 구축 및 기획(B2B)',
      '• 기 구축된 서비스 고도화/활성화',
    ],
    qualifications: [
      '• 전사적 자원 관리(ERP-엑셀 등으로 운영하던 관리부분을 IT 서비스로 전환) 기획 개발한 경험이 있으신 분(필수)',
      '• 프로젝트 일정 및 계획을 수립하고 진행 과정 전반의 관리를 담당해보신 분',
      '• 서비스 성장 전략을 고민하고 이를 제품에 적용하여 결과물을 만들어본 경험이 있으신 분',
      '• 고객중심적 사고를 통해 서비스 분석 및 설계가 가능하신 분',
      '• 커뮤니케이션을 위해 명확하고 간결한 문서를 작성할 수 있는 작문 능력을 갖추신 분',
    ],
    preferentials: [
      '• 상용화된 E-커머스(플랫폼) 서비스 기획 경험이 있으신 분',
      '• 프런트&백오피스 기획 경험이 있으신 분',
      '• IA, 화면기획, 정책기획 등 체계적인 기획 및 문서 정리가 가능하신 분',
      '• 서비스 담당자와의 유연한 커뮤니케이션이 가능하신 분',
      '• 니즈를 정확하게 캐치하실 수 있는 분',
      '• 아이디어나 개선안 제안에 적극적이신 분',
    ],
    developmentCultures: [
      { content: '• 애자일 프로세스 기반 프로젝트 진행' },
      { content: '• 온/오프라인 칸반 진행' },
      { content: '• 주기적인 코드 리뷰 진행' },
      {
        content: '• CDRI 기술 블로그',
        url: 'https://medium.com/cdri',
      },
    ],
    year: '3~5년',
    url: 'https://www.wanted.co.kr/wd/221671',
  },
  'frontend-developer': {
    title: '프론트엔드 개발자',
    team: '서비스개발본부',
    type: '정규직',
    mainTasks: [
      '• 화장품 인증 B2B 서비스(certicos) 고도화 프론트엔드 개발 및 운영',
      '• 화장품 커뮤니티(cosbridge) 고도화 프론트엔드 개발 및 운영',
      '• 임상 서비스(cosmetest) 고도화 프론트엔드 개발 및 운영',
      '• 프론트엔드 파트 리드',
    ],
    qualifications: [
      '• React 프레임워크를 깊이 이해하고 사용해 보신 분',
      '• HTML5, CSS3, Javascript 등 관련 기술에 능숙하신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    skills: [
      '• React, TypeScript, Next.js',
      '• React-Query, Redux, Recoil',
      '• Ant Design, Emotion, Styled-Components, Tailwind',
      '• AWS, GitHub Actions',
    ],
    developmentCultures: [
      { content: '• 애자일 프로세스 기반 프로젝트 진행' },
      { content: '• 온/오프라인 칸반 진행' },
      { content: '• 주기적인 코드 리뷰 진행' },
      {
        content: '• CDRI 기술 블로그',
        url: 'https://medium.com/cdri',
      },
    ],
    preferentials: [
      '• 웹 접근성 / 웹 표준 / 크로스브라우징에 대한 이해가 높으신 분',
      '• 반응형 웹 개발 경험이 풍부하신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 설계부터 개발, 출시, 운영의 경험이 있으신 분',
      '• 렌더링 최적화 등의 프론트엔드 성능 개선 경험이 있으신 분',
      '• 리더십이 있고, 팀을 이끌어 본 경험이 있으신 분',
    ],
    isDeveloper: true,
    year: '3년~8년',
    url: 'https://www.wanted.co.kr/wd/206124',
  },
  'backend-developer': {
    title: '백엔드 개발자',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• certicos 신규 웹 / 앱 서비스 레거시 없는 백엔드 개발',
      '• 화장품 인증 B2B 서비스 고도화 개발, 운영',
      '• 제조사/원료사 B2B 서비스 고도화 개발, 운영',
    ],
    qualifications: [
      '• 상용화된 웹 / 앱 서비스 백엔드 개발 경험이 있으신 분',
      '• Java Springboot Framework을 사용한 개발 경험이 풍부하신 분',
      '• MySQL, MariaDB 사용 경험이 있으신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    preferentials: [
      '• AWS등 Cloud 기반 서비스 운영 경험 있으신 분',
      '• Redis, MongoDB등 NoSQL 사용 경험',
      '• 데이터베이스 모델링 & 쿼리 튜닝이 가능하신 분',
      '• 대용량 데이터 분석 경험이 있으신 분',
      '• 변화하는 상황 속에서 유연하고 능동적으로 문제를 해결할 수 있는 분',
      '• 서비스의 초기 설계부터 개발, 출시, 운영의 경험이 있으신 분',
    ],
    isDeveloper: true,
    year: '3년 ~ 5년',
  },
  'ai-engineer': {
    title: 'AI 엔지니어',
    team: '서비스개발팀',
    type: '정규직',
    mainTasks: [
      '• 글로벌 화장품 규제 데이터를 통한 분류/예측 등의 AI 알고리즘 개발',
      '• 인증관련 글로벌 빅데이터 수집/가공/처리 수행',
    ],
    qualifications: [
      '• AI관련 상용화된 웹 / 앱 서비스 개발 경험이 있으신 분',
      '• 딥러닝 프레임워크 활용에 능숙하신 분(Pytorch, Tensorflow 등)',
      '• 업무 분야와 관련된 논문을 분석하고 구현, 검증하는 것에 관심 많으신 분',
      '• 새로운 기술에 적극적이고 능동적인 분',
      '• 협업에 적극적이고, 커뮤니케이션을 유연하게 잘 하시는 분',
    ],
    preferentials: [
      '• 인공지능 관련 학과 석사 이상 우대',
      '• 머신러닝/딥러닝 분야 경력 3년 이상 우대',
      '• 다국어 자연어 처리 가능하신 분 우대',
    ],
    isDeveloper: true,
  },
  'cosmetic-ra': {
    title: '글로벌 화장품 인허가(RA) 담당자',
    team: '자동화솔루션본부',
    type: '정규직',
    mainTasks: [
      '• 각 국가별 수출 서류 준비',
      '• 브랜드 국내외 인허가 담당',
      '• 브랜드 제품 관련 영문 문구/문안 검수',
    ],
    qualifications: [
      '• 초대졸 이상',
      '• 비즈니스 영어가 가능하신 분',
      '• 화장품 관련 기초 지식이 있으신 분',
      '• 원활한 커뮤니케이션 능력 및 협업성을 갖추신 분',
    ],
    year: '무관',
    url: 'https://www.saramin.co.kr/zf_user/jobs/view?rec_idx=49553850&view_type=etc&ref=recruit_manage',
  },
  consultant: {
    title: '컨설턴트',
    team: '컨설팅본부',
    type: '정규직',
    mainTasks: [
      '• 화장품 해외수출 인허가, 인체적용시험, 시험분석, 품질관리 등에 대한 컨설팅 및 영업',
      '• 화장품 원료사/제조사/브랜드사 대상 해외 인증 전산화 IT 서비스 영업',
      '• 화장품 원료사/제조사/브랜드사 대상의 커뮤니티 활성화',
    ],
    qualifications: [
      '• 영업적 역량과 적극적인 성향을 지니신 분',
      '• 고객의 입장에서 생각하고 니즈를 파악할 수 있는 분',
      '• 화장품 산업에 대한 이해가 높으신 분',
      '• 적극적인 태도와 긍정적인 마인드를 보유하신 분',
    ],
    preferentials: ['• 화장품 제조사 영업 경험'],
    year: '무관',
    url: 'https://www.saramin.co.kr/zf_user/jobs/view?rec_idx=49757096&view_type=etc&ref=recruit_manage',
  },
  qa: {
    title: '화장품 QA 담당자',
    team: '자동화솔루션본부',
    type: '정규직',
    mainTasks: [
      '• 화장품 QA 관리',
      '• QA 업무 이슈 및 대응',
      '• 화장품법 관련 표시 문구 검토',
    ],
    qualifications: [
      '• 초대졸 이상',
      '• 화장품 QA 관리 경력자',
      '• 원활한 커뮤니케이션 능력 및 협업성을 갖추신 분',
    ],
    year: '2~10년',
    url: 'https://www.saramin.co.kr/zf_user/jobs/view?rec_idx=49504664&view_type=etc&ref=recruit_manage',
  },
};

const JobDetail2 = () => {
  const { positionKey } = useParams<{ positionKey: PositionKey }>();
  const { setIsScrolling } = useScroll();
  const isMobile = useMediaQuery({
    query: breakPoint.MOBILE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });
  useLayoutEffect(() => {
    setIsScrolling(true);
  }, [setIsScrolling]);

  useEffect(() => {
    const mapScript = document.createElement('script');
    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=c4ae4174aa182f57eed619b7ca767248&autoload=false&libraries=services`;

    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      try {
        if (window.kakao) {
          window.kakao.maps.load(() => {
            const boContainer = document.getElementById('boMap');
            const boPosition = new window.kakao.maps.LatLng(
              37.4973502733284,
              127.038096510953,
            );

            const boOptions = {
              // HINT : 지도의 기본 위도, 경도 값 (화원빌딩)
              center: boPosition,
            };
            const boMap = new window.kakao.maps.Map(boContainer, boOptions);

            new window.kakao.maps.Marker({
              map: boMap,
              position: boPosition,
            });
          });
        } else {
          throw new Error('Invalid KakaoMap');
        }
      } catch {
        console.error('카카오 맵을 불러올 수 없습니다.');
      }
    };
    mapScript.addEventListener('load', onLoadKakaoMap);

    return () => mapScript.removeEventListener('load', onLoadKakaoMap);
  }, []);

  if (!positionKey || !Object.keys(positionMap).includes(positionKey)) {
    return (
      <PageLayout scrollSnap={false}>
        <Container isLD={isLD}>잘못된 접근입니다</Container>
      </PageLayout>
    );
  }
  const {
    title,
    team,
    type,
    mainTasks,
    qualifications,
    skills,
    developmentCultures,
    preferentials,
    year,
    url,
  } = positionMap[positionKey];

  return (
    <PageLayout scrollSnap={false}>
      <Container isLD={isLD}>
        <StyledFlex isLD={isLD} dir={!isLD ? 'row' : 'column'}>
          <LeftContainer>
            {!isLD && (
              <Header isMobile={isMobile} isTabletToLD={isTabletToLD}>
                <Typography.Title
                  type={isMobile ? 'h3' : isTabletToLD ? 'h2' : 'h1'}
                >
                  {title}
                </Typography.Title>
                <Typography.Title
                  css={css`
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34px;
                    margin-top: 16px;
                    color: #393e43;

                    ${isMobile &&
                    css`
                      margin-top: 8px;
                      font-size: 16px;
                      line-height: 28px;
                    `}
                    ${isTabletToLD &&
                    css`
                      margin-top: 8px;
                    `}
                  `}
                >
                  {team} - {type}
                </Typography.Title>
              </Header>
            )}
            <Content dir="column">
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🏢 CDRI 소개
              </Subtitle>
              <Typography.Title
                type="b2"
                color="DARK_GREY"
                css={css`
                  white-space: pre-line;

                  ${isMobile &&
                  css`
                    font-size: 14px;
                    line-height: 22x;
                  `};
                `}
              >
                CDRI는 화장품 인증과 관련된 솔루션을 자체 개발하고 있습니다.
                <br />
                우리는 좋은 제품을 빨리 알릴 수 없을까 고민하다 수출이 가장 빠른
                길 중에 하나라는 것을 알 수 있었습니다.
                <br /> 수출을 하려고 인증을 하다 보니 국가마다 법도 다르고
                에이전시마다 요구하는 서류도 달랐습니다.
                <br />이 모든 절차가 비효율적이라는 것을 깨닫게 되었고, 질문을
                하게 됩니다.
                <br />
                ‘왜 인증을 이렇게 밖에 할 수 없을까?’ 하고요.
                <br />
                분명 저희와 같은 고민을 하고 있는 화장품 제조사 또는
                브랜드사들이 있을 것이라고 생각합니다.
                <br />
                그래서 저희는 &apos;CERTICOS&apos;라는 인증 프로그램을 개발하게
                되었습니다.
                <br />
                이제 우리는 인증에 대해 다른 시각으로 바라보아야 합니다.
                <br />
                자동화 인증 이것이 CDRI의 핵심입니다.
                <br />
                대한민국에서 유일한 화장품 데이터 운영 및 해외 인증의 전산화
                서비스를 제공하는 CDRI에서 미래를 찾아보시기 바<br />
                랍니다.
              </Typography.Title>
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                📑 주요업무
              </Subtitle>
              {mainTasks.map((task) => (
                <Description
                  isMobile={isMobile}
                  isTabletToLD={isTabletToLD}
                  type="b2"
                  key={task}
                  dangerouslySetInnerHTML={{ __html: task }}
                />
              ))}
              <Subtitle
                type="h3"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                💡 자격요건
              </Subtitle>
              {qualifications.map((qualification) => (
                <Description
                  type="b2"
                  key={qualification}
                  isMobile={isMobile}
                  isTabletToLD={isTabletToLD}
                >
                  {qualification}
                </Description>
              ))}
              {skills && (
                <Fragment>
                  <SkillStack
                    type="b2"
                    isMobile={isMobile}
                    isTabletToLD={isTabletToLD}
                  >
                    <b>기술 스택/툴</b>
                  </SkillStack>
                  {skills.map((skill) => (
                    <Description
                      type="b2"
                      key={skill}
                      isMobile={isMobile}
                      isTabletToLD={isTabletToLD}
                    >
                      {skill}
                    </Description>
                  ))}
                </Fragment>
              )}
              {preferentials && (
                <Fragment>
                  <Subtitle
                    isMobile={isMobile}
                    type="h3"
                    isTabletToLD={isTabletToLD}
                  >
                    👍 우대사항
                  </Subtitle>
                  {preferentials.map((preferential) => (
                    <Description
                      type="b2"
                      key={preferential}
                      isMobile={isMobile}
                      isTabletToLD={isTabletToLD}
                    >
                      {preferential}
                    </Description>
                  ))}
                </Fragment>
              )}
              {developmentCultures && (
                <Fragment>
                  <Subtitle
                    type="h3"
                    isMobile={isMobile}
                    isTabletToLD={isTabletToLD}
                  >
                    🖥 개발 문화
                  </Subtitle>
                  {developmentCultures.map((developmentCulture, i) => (
                    <Description
                      type="b2"
                      key={developmentCulture.content}
                      isMobile={isMobile}
                      isTabletToLD={isTabletToLD}
                    >
                      {i === 3 ? (
                        <Fragment>
                          {developmentCulture.content}{' '}
                          <a
                            style={{ textDecoration: 'underline' }}
                            href={developmentCulture.url || ''}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {developmentCulture.url}
                          </a>
                        </Fragment>
                      ) : (
                        developmentCulture.content
                      )}
                    </Description>
                  ))}
                </Fragment>
              )}
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🎉 채용 전형
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 제출 서류 : 이력서와 자기소개서, 포트폴리오(
                {positionKey === 'e-commerce-planner'
                  ? '필수'
                  : '보유 시'}){' '}
              </Description>
              {(positionKey === 'frontend-developer' ||
                positionKey === 'e-commerce-planner') && (
                <Description
                  type="b2"
                  isMobile={isMobile}
                  isTabletToLD={isTabletToLD}
                >
                  • 제출 방법 : 이메일 제출(luke@cdri.pro / 자유 양식)
                </Description>
              )}
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 채용 절차 :{' '}
                <Typography.Text
                  weight={700}
                  type={isMobile ? 'b3' : 's2'}
                  color="DARK_GREY"
                  css={css`
                    word-break: keep-all;
                  `}
                >
                  서류 전형{title.includes('개발자') && '(사전 과제)'} &gt;
                  1차(실무자) 인터뷰 &gt; 최종(경영진) 인터뷰 &gt; 최종 오퍼
                  확정 후 오퍼 레터 발송
                </Typography.Text>
              </Description>
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                ✔️ 참고 사항
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 3개월의 수습기간이 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 입사 지원 서류에서 허위기재 사실이 발견될 경우, 입사가 취소될
                수 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 직무 또는 상황에 따라 전형 절차는 변경될 수 있습니다.
              </Description>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 국가유공자 및 장애인 등 취업보호대상자는 관계 법령에 의거하여
                우대합니다.
              </Description>
              <Subtitle
                isMobile={isMobile}
                type="h3"
                isTabletToLD={isTabletToLD}
              >
                🌟 혜택 및 복지
              </Subtitle>
              <Description
                type="b2"
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                <b>BENEFITS</b>
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
                style={{ marginTop: 8 }}
              >
                • 점심 및 저녁 식대 제공(점심 월 200,000원 / 저녁식대 일
                9,500원)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 연말 휴가(4일) 제공
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 기념일 연차 : 생일 연차 1일 제공(수습 통과 후)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 경조금과 경조휴가도 당연히 지원
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 팜스킨 제품을 원가보다 낮은 금액으로 구매
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 장기 근속 휴가 도입 (3년 : 10일 / 6년 : 15일)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 매년 우수사원 스톡옵션 제공
              </Description>
              <Description
                type="b2"
                css={css`
                  margin-top: 24px;

                  ${isMobile &&
                  css`
                    margin-top: 32px;
                  `}
                  ${isTabletToLD &&
                  css`
                    margin-top: 48px;
                  `}
                `}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                <b>LIFE</b>
              </Description>
              <Description
                style={{ marginTop: 8 }}
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 유연출퇴근제 (출근시간 08시~10시 / 코어근무시간 10시~17시)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 자유로운 복장 (모자에 반바지도 당연히 가능!)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 자유로운 연차 사용 (23년 연차 사용률 100%)
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 온오프 교육 & 헬스케어 등 연간 개인 성장지원금 60만원
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 희망 도서 신청 시 회사에서 지원 & 자유로운 책 리뷰 공유
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 팀의 원활한 소통을 위한 팀 빌딩비 지원
              </Description>
              <Description
                type={isMobile ? 'b3' : 'b2'}
                isMobile={isMobile}
                isTabletToLD={isTabletToLD}
              >
                • 자유롭게 의견을 개진하는 타운홀 미팅
              </Description>
            </Content>
          </LeftContainer>
          <RightContainer dir="column">
            {isLD && (
              <Header isMobile={isMobile} isTabletToLD={isTabletToLD}>
                <Typography.Title
                  type={isMobile ? 'h3' : isTabletToLD ? 'h2' : 'h1'}
                >
                  {title}
                </Typography.Title>
                <Typography.Title
                  css={css`
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34px;
                    margin-top: 16px;
                    color: #393e43;

                    ${isMobile &&
                    css`
                      margin-top: 8px;
                      font-size: 16px;
                      line-height: 28px;
                    `}
                    ${isTabletToLD &&
                    css`
                      margin-top: 8px;
                    `}
                  `}
                >
                  {team} - {type}
                </Typography.Title>
              </Header>
            )}
            <SummaryContainer>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  paddingBottom: '16px',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  직군
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  {title}
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  경력사항
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  {year}
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  borderBottom: `1px solid #E0E2E8`,
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  고용형태
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  정규직
                </Typography.Text>
              </Flex>
              <Flex
                gap={32}
                style={{
                  padding: '16px 0',
                }}
              >
                <Typography.Text
                  type="b3"
                  color="BLUE_GREY"
                  style={{ width: 52 }}
                >
                  근무지
                </Typography.Text>
                <Typography.Text type="b3" color="BLACK">
                  강남구 논현로 417 화원빌딩 가든층 A
                </Typography.Text>
              </Flex>
              <Map id="boMap" />
            </SummaryContainer>
            {!isLD &&
              !(
                positionKey === 'frontend-developer' ||
                positionKey === 'e-commerce-planner'
              ) && (
                <SubmitButton onClick={() => window.open(url, '_blank')}>
                  지원하기
                </SubmitButton>
              )}
          </RightContainer>
        </StyledFlex>
        <HashLink to="/job#positions" smooth>
          <Flex
            css={css`
              margin-top: 80px;
              margin-bottom: 120px;
              cursor: pointer;

              ${isMobile &&
              css`
                margin: 24px 0;
              `}
            `}
            gap={isMobile ? 8 : 14}
            align="center"
          >
            <ArrowLeft
              css={css`
                ${isMobile &&
                css`
                  width: 24px;
                  height: 24px;
                `}
                ${isTabletToLD &&
                css`
                  width: 40px;
                  height: 40px;
                `}
              `}
            />
            <Typography.Text
              type="b1"
              css={css`
                ${isMobile &&
                css`
                  font-size: 16px;
                  line-height: 24px;
                `}
                ${isTabletToLD &&
                css`
                  font-size: 18px;
                  line-height: 28px;
                `}
              `}
            >
              목록으로 돌아가기
            </Typography.Text>
          </Flex>
        </HashLink>
        {isLD &&
          !(
            positionKey === 'frontend-developer' ||
            positionKey === 'e-commerce-planner'
          ) && (
            <ButtonContainer>
              <SubmitButton onClick={() => window.open(url, '_blank')}>
                지원하기
              </SubmitButton>
            </ButtonContainer>
          )}
      </Container>
    </PageLayout>
  );
};

export default JobDetail2;

const Container = styled.section<{ isLD: boolean }>`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 200px;

  ${({ isLD }) =>
    isLD &&
    css`
      padding: 0 24px !important;
    `}
`;

const LeftContainer = styled(Flex)`
  flex-direction: column;
`;

const RightContainer = styled(Flex)`
  flex-direction: column;
  width: 368px;
  gap: 32px;
  @media ${breakPoint.LARGE_DEVICE} {
    width: 100%;
    margin-top: 116px;
  }
`;

const SummaryContainer = styled(Flex)`
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${palette.LIGHT_GREY2};
  padding: 16px 24px;

  @media ${breakPoint.LARGE_DEVICE} {
    padding: 24px;
  }
`;

const Header = styled.div<{ isMobile: boolean; isTabletToLD: boolean }>`
  /* ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 24px;
      margin-top: 116px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding-bottom: 32px;
      margin-top: 180px;
    `} */
`;

const StyledFlex = styled(Flex)<{ isLD: boolean }>`
  justify-content: space-between;
  ${({ isLD }) =>
    isLD &&
    css`
      flex-direction: column-reverse;
      justify-content: start;
    `}
`;

const Content = styled(Flex)`
  flex-direction: column;
`;

const Subtitle = styled(Typography.Title)<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  margin: 56px 0px 16px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 18px;
      line-height: 28px;
      margin: 32px 0px 8px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      font-size: 24px;
      line-height: 34px;
      margin: 48px 0px 16px;
    `}
`;

const Description = styled(Typography.Title)<{
  isMobile: boolean;
  isTabletToLD: boolean;
}>`
  b {
    font-weight: 700;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
      line-height: 22px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`;

const SkillStack = styled(Description)`
  margin: 32px 0px 16px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 32px 0px 8px;
    `}

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      margin: 48px 0px 16px;
    `}
`;

const SubDescription = styled(Typography.Title)`
  padding-left: 16px;
  color: ${palette.DARK_GREY};
`;

const ButtonContainer = styled.div`
  background-color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px 0;
  z-index: 100;
`;

const SubmitButton = styled(Flex)`
  background-color: ${palette.PRIMARY};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  width: 368px;
  height: 44px;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-in;
  @media ${breakPoint.LARGE_DEVICE} {
    width: 100%;
  }
  &:hover {
    background-color: #c21017;
  }
`;

const Map = styled.div`
  flex: 1 1 auto;
  height: 160px;
`;
