import { Route, Routes } from 'react-router-dom';

import Main from 'components/Main';
import AboutUs from 'components/AboutUs';
import Business from 'components/Business';
import Job from 'components/Job';
import Team from 'components/Team';
import Tech from 'components/Tech';
import CSCenter from 'components/CSCenter';
import JobDetail from 'components/Job/JobDetail';
import FAQ from 'components/FAQ/FAQ';

const RootRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/business" element={<Business />} />
      <Route path="/team" element={<Team />} />
      <Route path="/tech" element={<Tech />} />
      <Route path="/job" element={<Job />} />
      <Route path="/job/:positionKey" element={<JobDetail />} />
      <Route path="/cs" element={<CSCenter />} />
      <Route path="/faq">
        <Route index element={<FAQ />} />
        <Route path=":category" element={<FAQ />} />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
