import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Flex from 'components/ui/Flex';
import { palette } from 'shared/styles';
import Icon from 'components/ui/Icon/Icon';

const Pagination = ({
  data,
  page,
  setPage,
}: {
  data: unknown[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const pageLength = data.length / 10;

  if (pageLength <= 1) return null;

  return (
    <PageContainer justify="center">
      <Flex gap={8}>
        <PageButton>
          <Icon
            name="left"
            size={20}
            color={page > 1 ? 'BLACK' : 'GREY1'}
            onClick={() => {
              if (page === 1) return;
              setPage(page - 1);
            }}
          />
        </PageButton>
        {Array.from({ length: Math.ceil(data.length / 10) }, (_, i) => (
          <PageButton
            active={page === i + 1}
            key={i + 1}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </PageButton>
        ))}
        <PageButton>
          <Icon
            name="right"
            size={20}
            color={page < pageLength ? 'BLACK' : 'GREY1'}
            onClick={() => {
              if (page === pageLength) return;
              setPage(page + 1);
            }}
          />
        </PageButton>
      </Flex>
    </PageContainer>
  );
};

const PageContainer = styled(Flex)``;

const PageButton = styled.button<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 6px;
  background-color: white;
  width: 32px;
  height: 32px;
  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${palette.PRIMARY};
      color: ${palette.PRIMARY};
    `}
`;

export default Pagination;
