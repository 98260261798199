import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import { icons } from 'components/ui/Icon/icons';
import Icon from 'components/ui/Icon/Icon';

//카카오맵 주석처리
// declare global {
//   interface Window {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     kakao: any;
//   }
// }

const SectionA = () => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isTabletToMD = useMediaQuery({
    query: breakPoint.TABLET_TO_MEDIUM_DEVICE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });

  //카카오맵 주석처리
  // useEffect(() => {
  //   const mapScript = document.createElement('script');
  //   mapScript.async = true;
  //   mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=c4ae4174aa182f57eed619b7ca767248&autoload=false&libraries=services`;

  //   document.head.appendChild(mapScript);

  //   const onLoadKakaoMap = () => {
  //     try {
  //       if (window.kakao) {
  //         window.kakao.maps.load(() => {
  //           const hoContainer = document.getElementById('hoMap');
  //           const boContainer = document.getElementById('boMap');
  //           const hoPosition = new window.kakao.maps.LatLng(
  //             36.635939423043126,
  //             127.32564657502765,
  //           );
  //           const boPosition = new window.kakao.maps.LatLng(
  //             37.4973502733284,
  //             127.038096510953,
  //           );
  //           const hoOptions = {
  //             // HINT : 지도의 기본 위도, 경도 값 (오송)
  //             center: hoPosition,
  //           };
  //           const boOptions = {
  //             // HINT : 지도의 기본 위도, 경도 값 (화원빌딩)
  //             center: boPosition,
  //           };
  //           const hoMap = new window.kakao.maps.Map(hoContainer, hoOptions);
  //           const boMap = new window.kakao.maps.Map(boContainer, boOptions);
  //           new window.kakao.maps.Marker({
  //             map: hoMap,
  //             position: hoPosition,
  //           });
  //           new window.kakao.maps.Marker({
  //             map: boMap,
  //             position: boPosition,
  //           });
  //         });
  //       } else {
  //         throw new Error('Invalid KakaoMap');
  //       }
  //     } catch {
  //       console.error('카카오 맵을 불러올 수 없습니다.');
  //     }
  //   };
  //   mapScript.addEventListener('load', onLoadKakaoMap);

  //   return () => mapScript.removeEventListener('load', onLoadKakaoMap);
  // }, []);

  const cotactItemInfos: {
    [key: string]: {
      key: number;
      iconName: keyof typeof icons;
      title: string;
      desc1: string | React.ReactElement;
      desc2?: string;
      desc3?: string;
      info?: string | React.ReactElement;
    };
  } = {
    phone: {
      key: 1,
      iconName: 'phone',
      title: '전화 문의',
      desc1: '상담 가능 시간',
      desc2: '월 - 금 10:00 AM ~ 17:00 PM',
      desc3: '(13:00 PM ~ 14:00 PM 제외)',
      info: '02-6225-5253',
    },
    mail: {
      key: 2,
      iconName: 'mail',
      title: '이메일 문의',
      desc1: (
        <>
          이메일 문의는{' '}
          <Typography.Text style={{ fontWeight: 700 }}>
            info@cdri.pro
          </Typography.Text>{' '}
          로 접수해 주시면 담당 부서에서 확인 후 순차적으로 답변드리겠습니다
        </>
      ),
      info: (
        <MailButton
          css={css`
            padding: 16px 24px;
            font-size: 16px;

            ${isTabletToMD &&
            css`
              font-size: 16px;
              padding: 18px 24px;
            `}
            ${isMobile &&
            css`
              font-size: 14px;
              padding: 11px 24px;
            `}
          `}
          onClick={() => (window.location.href = 'mailto:info@cdri.pro')}
        >
          메일 보내기
        </MailButton>
      ),
    },
    location: {
      key: 3,
      iconName: 'location',
      title: '전화 문의',
      desc1:
        '(본사) 충청북도 청주시 흥덕구 오송읍 오송생명1로 194-25 청주SB플라자 510호 (28160)',
      desc2: '(지사) 서울 강남구 논현로 417 화원빌딩 가든층 A (06246)',
    },
  };
  return (
    <Container isTabletToLD={isTabletToLD} isMobile={isMobile}>
      <Typography.Text
        type="h1"
        css={css`
          ${isTabletToMD &&
          css`
            font-size: 36px;
            line-height: 46px;
          `}
          ${isMobile &&
          css`
            font-size: 24px;
            line-height: 34px;
          `}
        `}
      >
        CONTACT US
      </Typography.Text>
      <Flex
        direction="column"
        rowGap={24}
        css={css`
          margin-top: 40px;
          ${(isTabletToMD || isMobile) &&
          css`
            margin-top: 24px;
          `}
        `}
      >
        <Flex rowGap={24} columnGap={24} direction={isMD ? 'column' : 'row'}>
          {Object.values(cotactItemInfos)
            .filter((item) => item.iconName !== 'location')
            .map((item) => (
              <ContactItem
                key={item.key}
                isMobile={isMobile}
                isMD={isMD}
                gap={24}
              >
                <IconDiv>
                  <Icon name={item.iconName as keyof typeof icons} size={24} />
                </IconDiv>
                <Flex direction="column" style={{ marginTop: 4 }}>
                  <Typography.Text
                    css={css`
                      font-size: 24px;
                      font-weight: 700;
                      margin-bottom: 8px;
                      color: ${palette.BLACK};
                      ${isMobile &&
                      css`
                        font-size: 20px;
                      `}
                    `}
                  >
                    {item.title}
                  </Typography.Text>
                  <Typography.Text
                    css={css`
                      font-size: 18px;
                      font-weight: 500;
                      color: ${palette.BLACK};
                      margin-bottom: 4px;
                      white-space: pre-line;
                      ${item.iconName === 'mail' &&
                      css`
                        font-weight: 400;
                      `}
                      ${isMobile &&
                      css`
                        font-size: 16px;
                      `}
                    `}
                  >
                    {item.desc1}
                  </Typography.Text>
                  {item?.desc2 && (
                    <Typography.Text
                      css={css`
                        font-size: 18px;
                        font-weight: 400;
                        color: ${palette.BLACK};
                        white-space: pre-line;
                        ${isMobile &&
                        css`
                          font-size: 16px;
                        `}
                      `}
                    >
                      {item.desc2}
                    </Typography.Text>
                  )}
                  {item?.desc3 && (
                    <Typography.Text
                      css={css`
                        font-size: 18px;
                        font-weight: 400;
                        color: ${palette.BLACK};
                        white-space: pre-line;
                        ${isMobile &&
                        css`
                          font-size: 16px;
                        `}
                      `}
                    >
                      {item.desc3}
                    </Typography.Text>
                  )}
                  <Typography.Text
                    css={css`
                      margin-top: 42px;
                      font-size: 24px;
                      font-weight: 700;
                      color: ${palette.BLACK};

                      ${item.iconName === 'mail' &&
                      css`
                        ${isTabletToMD &&
                        css`
                          margin-top: 34px;
                        `}
                      `}
                      ${isMobile &&
                      css`
                        margin-top: ${item.iconName === 'mail'
                          ? '10px'
                          : '16px'};
                        font-size: 20px;
                      `}
                    `}
                  >
                    {item.info}
                  </Typography.Text>
                </Flex>
              </ContactItem>
            ))}
        </Flex>
        {Object.values(cotactItemInfos)
          .filter((item) => item.iconName === 'location')
          .map((item) => (
            <ContactItem
              key={item.key}
              isMobile={isMobile}
              isMD={isMD}
              gap={24}
            >
              <IconDiv>
                <Icon name="location" size={24} />
              </IconDiv>
              <Flex direction="column" style={{ marginTop: 4 }}>
                <Typography.Text
                  css={css`
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    color: ${palette.BLACK};

                    ${isMobile &&
                    css`
                      font-size: 20px;
                    `}
                  `}
                >
                  오시는 길
                </Typography.Text>
                <Flex rowGap={8} direction="column">
                  <Typography.Text
                    css={css`
                      font-size: 18px;
                      font-weight: 400;
                      color: ${palette.BLACK};
                      white-space: pre-line;
                      ${isMobile &&
                      css`
                        font-size: 16px;
                      `}
                    `}
                  >
                    {item.desc1}
                  </Typography.Text>
                  {item?.desc2 && (
                    <Typography.Text
                      css={css`
                        font-size: 18px;
                        font-weight: 400;
                        color: ${palette.BLACK};
                        white-space: pre-line;
                        ${isMobile &&
                        css`
                          font-size: 16px;
                        `}
                      `}
                    >
                      {item.desc2}
                    </Typography.Text>
                  )}
                </Flex>
              </Flex>
            </ContactItem>
          ))}
      </Flex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section<{ isTabletToLD: boolean; isMobile: boolean }>`
  min-height: calc(100vh - 162px);
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 200px;

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding: 180px 24px 120px !important;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 116px 24px 80px !important;
    `}
`;

const ContactItem = styled(Flex)<{ isMD: boolean; isMobile: boolean }>`
  flex: 1;
  padding: 32px;
  border-radius: 12px;
  color: ${palette.BLACK};
  border: 1px solid ${palette.GREY1};

  ${({ isMD }) =>
    isMD &&
    css`
      flex-direction: column;
      gap: 16px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 20px;
    `}
`;

const IconDiv = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 44px;
  min-width: 44px;
  height: 44px;
`;

const MailButton = styled.button`
  cursor: pointer;
  border: 1px solid ${palette.PRIMARY};
  border-radius: 8px;
  font-weight: 500;
  background-color: white;
  color: ${palette.PRIMARY};
`;
