import React from 'react';
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { Collapse, CollapseProps, Drawer, Input } from 'antd';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import faqData from '../../assets/file/faq.json';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Icon from 'components/ui/Icon/Icon';
import Pagination from 'components/ui/Pagination/Pagination';

const CATEGORY_PATH_NAME_MAP: { [key: string]: string } = {
  '화장품 수출': 'cosmetic-export-guide',
  '화장품 수출 인증 및 인허가': 'cosmetic-export-certification-and-licensing',
  '미국 수출 관련 MoCRA': 'cosmetic-export-to-the-us-mocra',
  '유럽 수출 관련 CPNP': 'cosmetic-export-to-europe-cpnp',
  '인도 수출 관련 CDSCO': 'cosmetic-export-to-india-cdsco',
  '화장품 품질 관리': 'cosmetic-export-quality-assurance',
  '서류 및 준비 절차': 'cosmetic-export-documentation',
  'CERTICOS 특장점 및 활용법': 'cosmetic-export-solution-certicos',
  '시장 진입 전략': 'cosmetic-export-market-entry-strategy',
  '기타 궁금증': 'cosmetic-export-additional-qna',
  전체: '',
};

const HighLightText = ({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}) => {
  if (!highlight) return <>{text}</>;

  const regex = new RegExp(
    `(${highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')})`,
    'gi',
  );
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ color: palette.PRIMARY }}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

const SectionA = ({ category }: { category?: string }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    Object.entries(CATEGORY_PATH_NAME_MAP).find(
      ([_, val]) => val === category,
    )?.[0] || '전체',
  );

  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [moreFaqCount, setMoreFaqCount] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState(searchInput);
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTabletToMD = useMediaQuery({
    query: breakPoint.TABLET_TO_MEDIUM_DEVICE,
  });
  const isTabletToLD = useMediaQuery({
    query: breakPoint.TABLET_TO_LARGE_DEVICE,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickDrawerOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (target.closest('.ant-drawer-mask')) {
        setOpenDrawer(false);
      }
    };

    document.body.addEventListener('click', handleClickDrawerOutside);

    return () => {
      document.body.removeEventListener('click', handleClickDrawerOutside);
    };
  }, []);

  useEffect(() => {
    const handler = debounce((value) => {
      setDebouncedSearch(value);
    }, 200);

    handler(searchInput);
    return () => handler.cancel();
  }, [searchInput]);

  const selectedFaq = useMemo(() => {
    const data = faqData.faq.filter(({ category }) =>
      selectedCategory === '전체' ? true : category === selectedCategory,
    );

    const filteredData = data.filter(({ question }) =>
      question.includes(debouncedSearch),
    );

    return debouncedSearch === '' ? data : filteredData;
  }, [selectedCategory, debouncedSearch]);

  const collapseItems: CollapseProps['items'] = useMemo(() => {
    const startIndex = (page - 1) * 10;

    return (
      isMobile
        ? selectedFaq.slice(1, moreFaqCount * 10)
        : selectedFaq.slice(startIndex, startIndex + 10)
    ).map(({ question, answer, category }, i) => ({
      key: i,
      label: (
        <Flex
          style={{
            width: '100%',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 8 : 56,
          }}
        >
          <Typography.Text
            css={css`
              min-width: 240px;
              font-size: 18px;
              font-weight: 500;
              @media screen and (${breakPoint.MOBILE}) {
                width: auto;
                font-size: 16px;
              }
            `}
            color="BLUE_GREY"
          >
            {category}
          </Typography.Text>
          <Typography.Text
            color="BLACK"
            css={css`
              font-size: 18px;
              font-weight: 500;
              color: ${palette.BLACK};
              white-space: pre-line;
              @media screen and (${breakPoint.MOBILE}) {
                font-size: 16px;
              }
            `}
          >
            <HighLightText text={question} highlight={searchInput} />
          </Typography.Text>
        </Flex>
      ),
      children: (
        <Typography.Text
          style={{ fontSize: isMobile ? 14 : 16, color: palette.DARK_GREY }}
        >
          {answer}
        </Typography.Text>
      ),
    }));
  }, [isMobile, moreFaqCount, page, searchInput, selectedFaq]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleMoreFAQ = () => {
    setMoreFaqCount((prev) => prev + 1);
  };

  const handleMovePathname = (category: string) => {
    navigate(`/faq/${CATEGORY_PATH_NAME_MAP[category]}`);
    setSelectedCategory(category);
    setPage(1);
    setActiveKey([]);
  };

  return (
    <Container isTabletToLD={isTabletToLD} isMobile={isMobile}>
      <Typography.Text
        type="h1"
        css={css`
          ${isTabletToMD &&
          css`
            font-size: 36px;
            line-height: 46px;
          `}
          ${isMobile &&
          css`
            font-size: 24px;
            line-height: 34px;
          `}
        `}
      >
        FAQ
      </Typography.Text>
      {isMobile ? (
        <Flex
          gap={8}
          align="center"
          style={{ marginTop: 14 }}
          onClick={handleOpenDrawer}
        >
          <Typography.Text
            style={{ color: palette.BLUE_GREY, fontSize: 16, fontWeight: 500 }}
          >
            {selectedCategory}
          </Typography.Text>
          <Icon name="down" size={16} color="BLUE_GREY" />
        </Flex>
      ) : (
        <CategoryContainer isisTabletToMD={isTabletToMD}>
          {faqData.categories.map((name, i) => (
            <CategoryItem
              $active={name === selectedCategory}
              onClick={() => {
                handleMovePathname(name);
              }}
              key={i}
            >
              {name}
            </CategoryItem>
          ))}
        </CategoryContainer>
      )}
      <SearchContainer isMobile={isMobile}>
        <SearchInput
          placeholder="찾으시는 내용을 검색해 보세요"
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <StyledSearchIcon isMobile={isMobile} />
      </SearchContainer>
      <FAQContainer isMobile={isMobile}>
        <Collapse
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key.length > 1 ? key[1] : key[0]);
          }}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <ArrowIcon
              name="up"
              isActive={isActive || false}
              size={isMobile ? 16 : 24}
              color="BLACK"
            />
          )}
          items={collapseItems}
        />
      </FAQContainer>
      {!isMobile ? (
        <Pagination data={selectedFaq} page={page} setPage={setPage} />
      ) : moreFaqCount < 10 &&
        selectedFaq.length > 0 &&
        selectedFaq.length !== 10 ? (
        <MoreFAQButton onClick={handleMoreFAQ}>FAQ 더보기</MoreFAQButton>
      ) : null}
      <Drawer
        className="drawer"
        open={openDrawer}
        placement="bottom"
        width={'100%'}
        closable={false}
      >
        <DrawerItems dir="column">
          {faqData.categories.map((name) => (
            <DrawerItem
              selected={name === selectedCategory}
              key={name}
              onClick={() => {
                setSelectedCategory(name);
                setOpenDrawer(false);
              }}
            >
              {name}
              {selectedCategory === name && (
                <Icon name="noCircleCheck" size={20} color="PRIMARY" />
              )}
            </DrawerItem>
          ))}
        </DrawerItems>
      </Drawer>
    </Container>
  );
};

export default SectionA;

const Container = styled.section<{ isTabletToLD: boolean; isMobile: boolean }>`
  min-height: calc(100vh - 162px);
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 200px;

  ${({ isTabletToLD }) =>
    isTabletToLD &&
    css`
      padding: 180px 24px 120px !important;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 116px 24px 80px !important;
    `}

  .ant-input {
    height: 56px;
    padding-left: 60px;
    font-size: 18px;
    ${({ isMobile }) =>
      isMobile &&
      css`
        font-size: 14px;
        height: 48px;
      `}
  }

  .ant-input-outlined:focus {
    border-color: ${palette.PRIMARY};
    box-shadow: none;
  }
  .ant-input-outlined:hover {
    border-color: ${palette.PRIMARY};
    box-shadow: none;
  }

  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px !important;
  }
  .ant-drawer .ant-drawer-body {
    padding: 0px !important;
  }
`;

const CategoryContainer = styled.div<{ isisTabletToMD?: boolean }>`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${({ isisTabletToMD }) =>
    isisTabletToMD &&
    css`
      ::-webkit-scrollbar {
        display: none;
      }

      overflow-x: scroll;
      flex-wrap: nowrap;
    `}
`;

const CategoryItem = styled.div<{ $active: boolean }>`
  display: inline-block;
  width: max-content;
  min-width: fit-content;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid ${palette.LIGHT_GREY3};
  background-color: white;
  color: #646f7c;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${palette.BLACK};
      border-color: ${palette.BLACK};
      color: white;
      transition: all 200ms linear;
    `}
`;

const SearchContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  margin-top: ${({ isMobile }) => (isMobile ? '18px' : ' 40px')};
`;

const SearchInput = styled(Input)``;

const StyledSearchIcon = styled(SearchIcon)<{ isMobile: boolean }>`
  position: absolute;
  left: 24px;
  top: ${({ isMobile }) => (isMobile ? '14px' : ' 18px')};
`;

const FAQContainer = styled(Flex)<{ isMobile: boolean }>`
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;

  .ant-collapse {
    border: none !important;
    width: 100% !important;
  }

  .ant-collapse-header {
    background-color: white !important;
    padding: 24px 0 !important;
    ${({ isMobile }) =>
      isMobile &&
      css`
        align-items: center !important;
      `}
  }

  .ant-collapse-item {
    background-color: white !important;
  }

  .ant-collapse-content {
    border-top: none !important;
    background-color: #f7f8f8;
    border-radius: 12px;
    font-size: ${({ isMobile }) =>
      isMobile ? '14px !important' : '18px !important'};
    white-space: pre-wrap;
    color: ${palette.DARK_GREY};
  }
  .ant-collapse-content-box {
    padding: ${({ isMobile }) =>
      isMobile ? '16px !important' : '32px !important'};
    margin-bottom: ${({ isMobile }) =>
      isMobile ? '16px !important' : '32px !important'};
  }

  .ant-collapse > .ant-collapse-item:last-child {
    border-radius: 0px !important;
  }
`;

const MoreFAQButton = styled.div`
  border-radius: 30px;
  padding: 13px 18px;
  color: ${palette.DARK_GREY};
  font-size: 14px;
  border: 1px solid ${palette.LIGHT_GREY3};
  width: max-content;
  margin: 0 auto;
`;

const DrawerItems = styled(Flex)`
  flex-direction: column;
  padding-top: 12px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DrawerItem = styled(Flex)<{ selected: boolean }>`
  color: ${palette.DARK_GREY};
  font-size: 14px;
  font-weight: 500;
  height: 56px;
  justify-content: space-between;
  padding: 17px 24px;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      color: ${palette.PRIMARY};
    `}
`;

const ArrowIcon = styled(Icon)<{ isActive: boolean }>`
  transform: rotate(180deg);
  transition: all 150ms ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(0deg);
    `}
`;
