import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useLocation, Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Logo from 'components/icons/Logo';
import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';
import { ReactComponent as HamburgerMenu } from 'assets/icons/hamburger-menu.svg';
import ResponsiveMenuPopup from 'components/ui/Popup/ResponsiveMenuPopup';
import CustomLink from 'components/ui/Link/CustomLink';
import { useScroll } from 'store/ScrollContext';

const Header = () => {
  const { isScrolling } = useScroll();
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const { pathname } = useLocation();
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  const subMenuRef = useRef<HTMLDivElement | null>(null);

  const hasBorderBottomNamePath =
    pathname === '/about-us' ||
    pathname === '/team' ||
    pathname === '/cs' ||
    pathname.includes('/faq');

  return (
    <Container
      isScrolling={isScrolling}
      hasBorderBottom={hasBorderBottomNamePath}
    >
      <Navigator justify="space-between" align="center">
        <CustomLink to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color={isScrolling ? 'NORMAL' : 'WHITE'} />
          </LogoDiv>
        </CustomLink>
        <MenuContainer>
          <CustomLink to="/about-us">
            <LinkText
              active={pathname === '/about-us'}
              isScrolling={isScrolling}
              type="s2"
              color="WHITE"
            >
              ABOUT US
            </LinkText>
          </CustomLink>
          <CustomLink to="business">
            <LinkText
              active={pathname === '/business'}
              isScrolling={isScrolling}
              type="s2"
              color="WHITE"
            >
              BUSINESS
            </LinkText>
          </CustomLink>
          <CustomLink to="team">
            <LinkText
              active={pathname === '/team'}
              isScrolling={isScrolling}
              type="s2"
              color="WHITE"
            >
              TEAM
            </LinkText>
          </CustomLink>
          <CustomLink to="tech">
            <LinkText
              active={pathname === '/tech'}
              isScrolling={isScrolling}
              type="s2"
              color="WHITE"
            >
              TECH
            </LinkText>
          </CustomLink>
          <CustomLink to="job">
            <LinkText
              active={pathname.includes('/job')}
              isScrolling={isScrolling}
              type="s2"
              color="WHITE"
            >
              JOB
            </LinkText>
          </CustomLink>
          <ClickLink
            style={{ position: 'relative' }}
            onClick={() => {
              if (
                !subMenuRef.current?.classList.contains('visible') ||
                subMenuRef.current.classList.contains('hidden')
              ) {
                subMenuRef?.current?.classList.add('visible');
                subMenuRef?.current?.classList.remove('hidden');
              } else {
                subMenuRef?.current?.classList.remove('visible');
                subMenuRef?.current?.classList.add('hidden');
              }
            }}
          >
            <LinkText
              active={pathname === '/cs' || pathname.includes('/faq')}
              isScrolling
              type="s2"
              color="WHITE"
            >
              SUPPORT
            </LinkText>
            <SubMenu ref={subMenuRef}>
              <CustomLink to="cs">
                <SubMenuItem>CONTACT US</SubMenuItem>
              </CustomLink>
              <CustomLink to="faq">
                <SubMenuItem>FAQ</SubMenuItem>
              </CustomLink>
            </SubMenu>
          </ClickLink>
        </MenuContainer>
      </Navigator>
      <ResponsiveNavigator justify="space-between">
        <Link to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color={isScrolling ? 'NORMAL' : 'WHITE'} />
          </LogoDiv>
        </Link>
        <HamburgerMenu
          fill={isScrolling ? palette.BLACK : '#ffffff'}
          onClick={() => {
            setResponsiveMenuVisible(true);
          }}
        />
      </ResponsiveNavigator>
      <ResponsiveMenuPopup
        visible={responsiveMenuVisible}
        onClose={() => setResponsiveMenuVisible(false)}
      />
    </Container>
  );
};

export default Header;

const Container = styled.div<{
  isScrolling: boolean;
  hasBorderBottom: boolean;
}>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  z-index: 100;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid ${palette.LIGHT_GREY2};
    `}

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      background-color: ${palette.WHITE};

      span {
        color: ${palette.DARK_GREY};
      }
    `}
  .visible {
    visibility: visible;
  }
  .hidden {
    visibility: hidden;
  }
`;

const Navigator = styled(Flex)`
  max-width: 1200px;
  margin: 0 auto;

  @media ${breakPoint.SMALL_DEVICE} {
    display: none;
  }

  @media ${breakPoint.LARGE_DEVICE} {
    padding: 0 24px;
  }
`;

const MenuContainer = styled(Flex)`
  height: 80px;
  gap: 80px;
  @media ${breakPoint.MEDIUM_DEVICE} {
    gap: 40px;
  }
`;

const LogoDiv = styled.div<{ $isMobile: boolean }>`
  svg {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        width: 60px;
        height: 24px;
      `}
  }
`;

const LinkText = styled(Typography.Text)<{
  active: boolean;
  isScrolling: boolean;
}>`
  display: block;
  padding: 28px 0;
  transition: border-bottom 0.3s ease-in-out;

  ${({ active, isScrolling }) =>
    active &&
    css`
      border-bottom: 2px solid ${isScrolling ? palette.PRIMARY : palette.WHITE};
    `}
`;

const ResponsiveNavigator = styled(Flex)`
  display: none;
  @media ${breakPoint.SMALL_DEVICE} {
    display: flex;
    height: 80px;
    padding: 24px 24px 0px;
  }

  @media ${breakPoint.MOBILE} {
    display: flex;
    height: 56px;
    padding: 16px 24px 0px;
  }
`;
const SubMenu = styled(Flex)`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 10px;
  padding: 8px 0;
  flex-direction: column;
  position: absolute;
  top: 88px;
  visibility: hidden;
`;

const ClickLink = styled.div`
  cursor: pointer;
`;

const SubMenuItem = styled.div`
  padding: 11px 16px;
  font-size: 15px;
  color: ${palette.BLACK};
  width: 144px;
  font-weight: 400;
  &:hover {
    background-color: ${palette.LIGHT_GREY1};
  }
`;
