import styled from '@emotion/styled';

const Flex = styled.div<{
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  gap?: number;
  rowGap?: number;
  columnGap?: number;
  $wrap?: boolean;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  gap: ${(props) => props.gap && `${props.gap}px`};
  column-gap: ${(props) => props.columnGap && `${props.columnGap}px`};
  row-gap: ${(props) => props.rowGap && `${props.rowGap}px`};
  flex-wrap: ${({ $wrap }) => ($wrap ? 'wrap' : 'nowrap')};
`;

export default Flex;

Flex.defaultProps = {
  justify: 'normal',
  align: 'normal',
  gap: 0,
  rowGap: 0,
  $wrap: false,
};
