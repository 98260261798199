import { useLayoutEffect } from 'react';

import PageLayout from 'components/layout/PageLayout';
import SectionA from './SectionA';
import { useScroll } from 'store/ScrollContext';

const CSCenter = () => {
  const { setIsScrolling } = useScroll();

  useLayoutEffect(() => {
    setIsScrolling(true);
  }, [setIsScrolling]);
  return (
    <PageLayout scrollSnap={false}>
      <SectionA />
    </PageLayout>
  );
};

export default CSCenter;
