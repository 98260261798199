import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import useScroll from 'hooks/useScroll';
import { breakPoint } from 'shared/styles';

const SectionA = () => {
  const target = useScroll();
  const isLD = useMediaQuery({
    query: breakPoint.LARGE_DEVICE,
  });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTTLD = useMediaQuery({ query: breakPoint.TABLET_TO_LARGE_DEVICE });
  return (
    <Container ref={target}>
      <video loop autoPlay muted preload="none">
        <source
          src={`//cdri.s3.ap-northeast-2.amazonaws.com/assets/tech-${
            isMobile ? 'mobile' : isTTLD ? 'tablet' : 'web'
          }.mp4`}
          type="video/mp4"
        />
      </video>
      <StyledFlex direction="column" justify="center">
        <Typography.Title
          type="h1"
          color="WHITE"
          css={css`
            position: relative;

            @media screen and (${breakPoint.MOBILE}) {
              font-size: 24px;
              line-height: 34px;
            }
            @media screen and (${breakPoint.TABLET}) {
              font-size: 36px;
              line-height: 46px;
            }
          `}
        >
          인증에 대한 새로운 접근
        </Typography.Title>
        <Typography.Title
          type="b1"
          color="WHITE"
          css={css`
            position: relative;
            white-space: pre-line;
            word-break: keep-all;
            margin-top: 38px;

            @media screen and (${breakPoint.MOBILE}) {
              font-size: 16px;
              line-height: 24px;
            }
            @media screen and (${breakPoint.TABLET}) {
              font-size: 18px;
              line-height: 28px;
            }
            @media screen and (${breakPoint.LARGE_DEVICE}) {
              margin-top: 32px;
            }
          `}
        >
          {`지금까지 사람이 지식과 경험을 바탕으로 화장품 수출에 필요한 인허가를 어렵고 복잡하게 진행했다면,${
            isLD ? ' ' : '\n'
          } CDRI의 AI 기반 화장품 자동화 인증 시스템은 인공지능을 활용하여 고객사에 보다 빠르고 정확한 인허가 경험을 제공합니다.\n인증 프로세스는 매우 어렵고 복잡하기 때문에 누구나 개선하고 싶어 합니다.${
            isLD ? ' ' : '\n'
          } CDRI는 상상만 했던 인증 프로세스의 개선을 실현하였습니다.`}
        </Typography.Title>
      </StyledFlex>
    </Container>
  );
};

export default SectionA;

const Container = styled.section`
  position: relative;
  background-color: #000;
  overflow: hidden;
  height: 100vh;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (${breakPoint.LARGE_DEVICE}) {
    width: 100%;
    padding: 0 24px;
  }
`;
