import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';

import Flex from '../Flex';
import { breakPoint, palette } from 'shared/styles';
import Typography from '../Typography';
import Logo from 'components/icons/Logo';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import Icon from '../Icon/Icon';

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 2px 6px 16px rgba(17, 81, 128, 0.08);
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transform: ${({ visible }) => !visible && `translateY(-100%)`};
  transition: all 0.3s ease-out;
  min-height: 584px;

  @media ${breakPoint.TABLET_OVER} {
    display: none;
  }
`;

const Header = styled(Flex)`
  display: flex;
  height: 80px;
  padding: 24px 24px 0px;

  @media ${breakPoint.MOBILE} {
    height: 56px;
    padding: 16px 24px 0px;
  }
`;

const ResponsiveMenuPopup = ({
  visible,
  className,
  onClose,
}: {
  visible: boolean;
  className?: string;
  onClose: () => void;
}) => {
  const [clickedSubMenu, setClickedSubMenu] = useState(false);

  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });

  const handleOpenSubMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setClickedSubMenu(!clickedSubMenu);
  };

  const handleClose = (closable: boolean) => {
    if (closable) onClose();
  };

  return (
    <Container
      className={className}
      visible={visible}
      onClick={() => handleClose(true)}
    >
      <Header justify="space-between">
        <Link to="/">
          <LogoDiv $isMobile={isMobile}>
            <Logo color="NORMAL" />
          </LogoDiv>
        </Link>
        <Close onClick={onClose} />
      </Header>
      <Flex direction="column">
        <Link to="/about-us">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            ABOUT US
          </LinkText>
        </Link>
        <Link to="/business">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            BUSINESS
          </LinkText>
        </Link>
        <Link to="/team">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            TEAM
          </LinkText>
        </Link>
        <Link to="/tech">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            TECH
          </LinkText>
        </Link>
        <Link to="/job">
          <LinkText
            $isMobile={isMobile}
            type={isMobile ? 'b3' : 's2'}
            weight={400}
          >
            JOB
          </LinkText>
        </Link>
        <ClickSubLink onClick={handleOpenSubMenu}>
          <Flex
            justify="space-between"
            align="center"
            style={{
              padding: '21px 24px',
              zIndex: 2,
              background: clickedSubMenu ? '#F7F8F8' : 'white',
            }}
          >
            <Typography.Text
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: palette.DARK_GREY,
              }}
            >
              SUPPORT
            </Typography.Text>
            <ArrowDiv clicked={clickedSubMenu}>
              <Icon name="up" size={20} color="BLACK" />
            </ArrowDiv>
          </Flex>
          <SubMenus visible={clickedSubMenu} onClick={() => handleClose(true)}>
            <SubMenuItem to="cs">
              <LinkText
                $isMobile={isMobile}
                type={isMobile ? 'b3' : 's2'}
                weight={400}
                style={{ fontSize: 14 }}
              >
                CONTACT US
              </LinkText>
            </SubMenuItem>
            <SubMenuItem to="faq">
              <LinkText
                $isMobile={isMobile}
                type={isMobile ? 'b3' : 's2'}
                weight={400}
                style={{ fontSize: 14 }}
              >
                FAQ
              </LinkText>
            </SubMenuItem>
          </SubMenus>
        </ClickSubLink>
      </Flex>
    </Container>
  );
};

export default ResponsiveMenuPopup;

const LinkText = styled(Typography.Text)<{ $isMobile: boolean }>`
  display: block;
  padding: 28px 0px 28px 24px;
  color: ${palette.DARK_GREY};
  font-weight: 500;
  font-size: 16px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 21px 0 21px 24px;
    `};
`;

const LogoDiv = styled.div<{ $isMobile: boolean }>`
  svg {
    ${({ $isMobile }) =>
      $isMobile &&
      css`
        width: 60px;
        height: 24px;
      `}
  }
`;

const ClickSubLink = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  background-color: white;
`;

const SubMenus = styled(Flex)<{ visible: boolean }>`
  background-color: white;
  flex-direction: column;
  transition: all 200ms ease-in;
  z-index: 1;

  ${({ visible }) =>
    visible
      ? css`
          transform: translateY(0%);
        `
      : css`
          transform: translateY(-100%);
          opacity: 0;
        `}
`;
const SubMenuItem = styled(Link)`
  padding-left: 40px;
  font-size: 14px;
`;

const ArrowDiv = styled.div<{ clicked: boolean }>`
  transform: rotate(180deg);
  transition: all 150ms ease-in;

  ${({ clicked }) =>
    clicked &&
    css`
      transform: rotate(0deg);
    `}
`;
