import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useScroll } from 'store/ScrollContext';

export const useScrollMiddleware = () => {
  const { setIsScrolling } = useScroll();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (to: string) => {
      if (
        to.includes('faq') ||
        to.includes('about-us') ||
        to.includes('team') ||
        to.includes('cs')
      ) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }

      navigate(to);
    },
    [setIsScrolling, navigate],
  );

  return handleNavigate;
};
