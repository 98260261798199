import { useLayoutEffect } from 'react';
import PageLayout from 'components/layout/PageLayout';
import SectionA from './SectionA';
import { useScroll } from 'store/ScrollContext';
import { useParams } from 'react-router-dom';

const FAQ = () => {
  const { setIsScrolling } = useScroll();
  const { category } = useParams<{ category: string }>();

  useLayoutEffect(() => {
    setIsScrolling(true);
  }, [setIsScrolling]);
  return (
    <PageLayout scrollSnap={false}>
      <SectionA category={category} />
    </PageLayout>
  );
};

export default FAQ;
