import { useCallback } from 'react';
import useIntersectionObserver from './useIntersectionObserver';
import { useScroll } from 'store/ScrollContext';

const useScrollAction = () => {
  const { setIsScrolling } = useScroll();

  const handleIntersect = useCallback(
    (isIntersecting: boolean) => {
      setIsScrolling(!isIntersecting);
    },
    [setIsScrolling],
  );

  const target = useIntersectionObserver({
    onIntersect: handleIntersect,
    threshold: 0.95,
  });

  return target;
};
export default useScrollAction;
