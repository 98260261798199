import { Link } from 'react-router-dom';
import { useScrollMiddleware } from 'hooks/useScrollMiddleware';

const CustomLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const handleNavigate = useScrollMiddleware();

  return (
    <Link
      to={to}
      onClick={(e) => {
        e.preventDefault();
        handleNavigate(to);
      }}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
